<template>
  <CCard class="mr-2 d-md-flex d-none" style="box-shadow: none">
    <teleport v-if="mountedComponent" to="#header-nav">
      <CBreadcrumbItem active> Documents </CBreadcrumbItem>
    </teleport>

    <CCardBody class="p-0 d-flex">
      <div class="contracts__filter pt-2 pb-2">
        <Filter v-model="type" :list="filterList" class="mb-4" />
<!--        <Tags v-model="tags" :list="tagsList" />-->
      </div>

      <div class="contracts__table pt-2 pb-2">
        <Table :tags="tags" :type="type" />
      </div>
    </CCardBody>
  </CCard>

  <CCard class="mr-2 d-md-none d-block" style="box-shadow: none">
    <CCardBody class="p-0">
      <CAccordion :active-item-key="1">
        <Wrap
          v-for="(item, index) in filterList"
          :key="item.value"
          :index="index"
          @open="type = item.value"
        >
          <template #title>
            <CIcon :name="item.icon" class="mr-2" />{{ item.label }}
          </template>
          <Table v-if="type === item.value" :tags="tags" :type="type" />
        </Wrap>
      </CAccordion>
      <CAccordion>
<!--        <Wrap :title="'Tags'">-->
<!--          <Tags v-model="tags" :list="tagsList" :label-vis="false" />-->
<!--        </Wrap>-->
      </CAccordion>
    </CCardBody>
  </CCard>
</template>

<script src="./script.js"></script>
<style lang="scss">
@import 'style';
</style>
