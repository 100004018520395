<template>
  <div class="d-flex align-items-center mb-2 mt-2 mx-3 flex-wrap flex-md-nowrap">
    <div class="d-block d-md-flex w-100">
      <CDropdown
        :key="`${selected.length}-${type}`"
        color="primary"
        class="mr-2 mb-2 w-md-auto w-100 d-flex"
        style="min-width: 200px"
      >
        <CDropdownToggle
          color="primary"
          variant="outline"
          :key="`${selected.length}`"
          :disabled="!selected.length"
          class="text-start d-flex align-items-center justify-content-between"
        >
          Bulk Action
        </CDropdownToggle>
        <CDropdownMenu>
          <!-- <CDropdownItem v-if="type === 'archive'" @click="restore">
            Restore
          </CDropdownItem> -->
          <CDropdownItem @click="download">
            Download
          </CDropdownItem>
        </CDropdownMenu>
      </CDropdown>

      <CInputGroup class="mr-2 mb-2 w-md-auto w-100">
        <CInputGroupText>
          <CIcon name="cil-search"/>
        </CInputGroupText>
        <CFormInput v-model="search" placeholder="Search" />
      </CInputGroup>
    </div>
  </div>
  <div class="px-2">
    <SmartTable
      :items="list"
      :items-per-page="parseInt(perPage)"
      :columns="fields"
      :sorter-value="sort"
      @sorter-change="onSort"
      selectable="task_id"
      :selected-items="selected"
      @selected-items-change="s => selected = s"
      :loading="loadingTable"
      :active-page="activePage"
      @update-active-page="(value) => (activePage = value)"
      :per-page="perPage"
      @update-per-page="(value) => (perPage = value)"
      :pages="pages"
      :clear-text="search.length ? 'No search results found' : 'No Data yet'"
    >
      <template #status="{ item, column }">
        <CBadge v-if="column.key === 'file_name'" color="primary">{{
            item.status
          }}</CBadge>
      </template>
      <template #quick_access="{item}">
        <td>
          <Star @click="changeFav(item)" :active="item.quick_access" />
        </td>
      </template>
    </SmartTable>
  </div>
</template>

<script>
import table from '@/mixin/table'
import Star from '@/components/Star'

export default {
  components: { Star },
  mixins: [table],
  inject: ['toast', 'downloadToast'],
  props: {
    tags: {},
    type: {}
  },
  data:(v) => ({
    selected: [],
    loadingTable: false,
    list: [],
    fields: [
      { key: 'quick_access', label: '', _style:'max-width: 30px; min-width: auto', sorter: false },
      { key: 'file_name', label: 'File Name', _style:'', click: (item) => v.goToView(item) },
      { key: 'project_name', label: 'Project Name', _style:'', link: { name: 'ProjectDashboard', params: { id: 'project_id' } } },
      { key: 'project_number', label: 'Project Number', _style: '' },
      { key: 'task_name', label: 'Task Name', _style:'', click: (item) => v.goToView(item) },
      { key: 'added_at', label: 'Date Added', _style:'' },
      { key: 'added_by', label: 'Added By', _style:'' },
    ],
  }),
  watch: {
    search() {
      this.selected = []
    },
    'tags.length'() { this.fetchData(); this.selected = [] },
    type() { this.fetchData(); this.selected = [] }
  },
  methods: {
    goToView(item) {
      if (item.type === 'Request documents') {
        this.$router.push({
          name: 'TaskRequest',
          params: { id: item.project_id, task_id: item.task_id },
        })
      } else if (item.type === 'Contracts') {
        this.$router.push({
          name: 'TaskContracts',
          params: { id: item.project_id, task_id: item.task_id },
        })
      } else if (item.type === 'Acknowledge') {
        this.$router.push({
          name: 'TaskAcknowledge',
          params: { id: item.project_id, task_id: item.task_id },
        })
      }
    },
    fetchData(clear = true) {
      if (clear) {
        this.loadingTable = true
        this.list = []
      }
      this.$http.companies.companyDocuments({ params: {
        company_id: this.$route.params.id,
        per_page: this.perPage,
        page: this.activePage,
        sort_field: this.sort && this.sort.column || 'added_at',
        sort_direction: this.sort && this.sort.state || 'desc',
        search: this.search,
        tags: this.tags,
        archive: this.type === 'archive',
        quick_access: this.type === 'quick_access'
      }})
        .then(res => {
          this.pages = res.data.meta.last_page
          this.list = res.data.data.map(i => ({
            ...i,
            file_name: i.file_name,
            task_name: i.task_name,
            project_name: i.project_name,
            added_at: i.added_at ? this.$BtoF({value: i.added_at}) : null,
            added_by: i.added_by,
          }))
        })
        .finally(() => {
          this.loadingTable = false
        })
    },
    download() {
      this.$http.common
        .downloadInitJob({
          action: 'company_documents',
          company_id: this.$route.params.id,
          tasks: this.selected,
        })
        .then(({ data }) => {
          this.downloadToast.open(data.download_token, data.preview_title)
        })
        .catch((error) => this.toast('error', error.response.data.message))
    },
    restore() {},
    setCheck(value) {
      this.selected = value.map(i => i.task_id)
    },
    changeFav(item) {
      if (item.quick_access) {
        this.$http.companies.removeToQuickAccess({ tasks: [item.task_id] })
          .then(() => {
            this.fetchData(false)
          })
      } else {
        this.$http.companies.addToQuickAccess({ tasks: [item.task_id] })
          .then(() => {
            this.fetchData(false)
          })
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
